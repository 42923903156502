import React from "react";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { Image } from "../../types";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";

export type CookAtHomeProps = {
  isPublished?: boolean;
  title: string;
  description: string;
  ctaLabel: string;
  recipeTitle: string;
  recipeSubtitle: string;
  image: Image;
  ctaLink: string;
  recipeLink: string;
};

export const CookAtHome: React.FC<CookAtHomeProps> = (
  props: CookAtHomeProps
) => {
  if (
    (!props?.title && !props?.description && !props?.image) ||
    !props?.isPublished
  ) {
    return null;
  }

  return (
    <Container>
      <FeaturedPart
        href={props.recipeLink}
        aria-label={`${props.recipeTitle} ${props.recipeSubtitle}`}
      >
        <img src={props.image.src} alt={props.image.alt} />
        <div>
          <FeaturedLabel>{props.recipeTitle}</FeaturedLabel>
          <FeaturedTitle>{props.recipeSubtitle}</FeaturedTitle>
        </div>
      </FeaturedPart>
      <TextPart>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
        <ButtonLink
          type={ButtonType.PRIMARY_DARK}
          label={props.ctaLabel}
          link={props.ctaLink}
          className="cta"
        />
      </TextPart>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  background: #ccb983;
  ${MEDIA_DESKTOP} {
    flex-direction: row;
    gap: ${vwDesktop(126)};
    height: ${vwDesktop(572)};
    padding-inline: ${vwDesktop(205)};
  }
`;

const TextPart = styled.div`
  padding-inline: ${vwMobile(24)};
  padding-block: ${vwMobile(112)} ${vwMobile(40)};
  ${MEDIA_DESKTOP} {
    padding-inline: ${vwMobile(0)};
    padding-block: 0;
    width: ${vwDesktop(399)};
    margin-top: ${vwDesktop(121)};
    .cta {
      width: ${vwDesktop(180)};
    }
  }

  .cta {
    > span {
      background: none !important;
    }
    > div {
      background: #012102 !important;
      transition: background-color 0.3s;
    }
    &:hover {
      > div {
        background: #3e3e3e !important;
      }
    }
  }
`;

const Title = styled.h2`
  font-size: ${rem(36)};
  line-height: ${rem(40)};
  letter-spacing: 0.04em;
  font-weight: 900;
  margin-bottom: ${vwMobile(6)};
  color: inherit;
  ${MEDIA_DESKTOP} {
    font-size: ${rem(60)};
    line-height: ${rem(60)};
    letter-spacing: 0.03em;
    margin-bottom: ${vwDesktop(11)};
  }
`;

const Description = styled.p`
  font-size: ${rem(18)};
  font-weight: 600;
  line-height: ${rem(24)};
  margin-bottom: ${vwMobile(45)};
  color: inherit;
  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(41)};
  }
`;

const FeaturedPart = styled.a`
  position: relative;
  cursor: pointer;

  ${MEDIA_DESKTOP} {
    top: ${vwDesktop(-65)};
    width: ${vwDesktop(504)};
    height: ${vwDesktop(505)};
  }

  > div {
    position: absolute;
    background: #544423;
    left: ${vwMobile(12)};
    right: ${vwMobile(12)};
    bottom: ${vwMobile(-74)};
    padding-inline: ${vwMobile(28)};
    padding-block: ${vwMobile(24)} ${vwMobile(29)};
    min-height: ${vwMobile(133)};
    height: fit-content;

    ${MEDIA_DESKTOP} {
      left: ${vwDesktop(24)};
      right: ${vwDesktop(24)};
      bottom: ${vwDesktop(-92)};
      min-height: ${vwDesktop(161)};
      height: fit-content;
      padding-block: ${vwDesktop(28)} ${vwDesktop(29)};
      padding-inline: ${vwDesktop(25)} ${vwDesktop(24)};
    }
  }

  img {
    width: 100%;
    height: ${vwMobile(505)};
    object-fit: cover;
    ${MEDIA_DESKTOP} {
      object-fit: contain;
      width: ${vwDesktop(504)};
      height: ${vwDesktop(505)};
    }
  }
`;

const FeaturedLabel = styled.h4`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 800;
  letter-spacing: 0.08em;
  color: #ccb983;
  text-transform: uppercase;
  margin-bottom: ${vwMobile(4)};

  ${MEDIA_DESKTOP} {
    height: ${vwDesktop(19)};
    margin-bottom: ${vwDesktop(9)};
  }
`;

const FeaturedTitle = styled.h3`
  font-size: ${rem(24)};
  line-height: ${rem(28)};
  letter-spacing: 0.04em;
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;

  ${MEDIA_DESKTOP} {
    font-size: ${rem(36)};
    line-height: ${rem(40)};
    letter-spacing: 0.03em;
  }
`;
