import React from "react";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import DetailInfo, { DetailSection } from "./DetailInfo";

export type AccordionProps = {
  items?: DetailSection[];
  viewProductLabel: string;
  collapseProductLabel: string;
  quantityLabel: string;
  onChange: (index?: number) => void;
  open?: number;
};

const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
  const { open } = props;
  const handleItemClick = (event: React.MouseEvent, index?: number) => {
    const el = event.target as HTMLElement;
    props.onChange(index === open ? undefined : index);
    setTimeout(() => {
      window.scroll({
        top: el.offsetTop - 10,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <Container>
      {(props.items ?? []).map((item, index) => {
        return (
          <>
            <Item
              key={`accordion-item-${index}`}
              onClick={(e) => handleItemClick(e, index)}
            >
              <div>
                <img src={item.smallImage.src} alt={item.smallImage.alt} />
              </div>
              <ItemText>
                <ItemTitle
                  dangerouslySetInnerHTML={{
                    __html: item?.name,
                  }}
                />
                <FooterWrapper>
                  <Quantity>
                    {props?.quantityLabel}: {item?.quantity}
                  </Quantity>
                  <ItemLink className="viewProduct">
                    {index === open
                      ? props.collapseProductLabel
                      : props.viewProductLabel}
                  </ItemLink>
                </FooterWrapper>
              </ItemText>
            </Item>
            {index === open && <DetailInfo product={item} />}
          </>
        );
      })}
    </Container>
  );
};

export default Accordion;

const Container = styled.div`
  width: 100%;
  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(399)};
  }
`;

const Item = styled.div`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  color: #fff;
  display: flex;
  border-bottom: ${vwMobile(1)} solid #3a6249;
  margin-bottom: ${vwMobile(17)};
  padding-bottom: ${vwMobile(17)};
  gap: ${vwMobile(7)};
  cursor: pointer;

  &:hover {
    .viewProduct {
      text-decoration: underline;
    }
  }

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(7)};
    margin-bottom: ${vwDesktop(17)};
    padding-bottom: ${vwDesktop(17)};
    border-width: ${vwDesktop(1)};
  }

  img {
    width: ${vwMobile(70)};
    height: ${vwMobile(48)};
    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(70)};
      height: ${vwDesktop(48)};
    }
  }
`;

const ItemText = styled.div`
  flex: 1;
`;

const ItemTitle = styled.h5`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 600;
  letter-spacing: 0.01em;
  margin-bottom: ${vwMobile(8)};

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(12)};
  }
`;

const ItemLink = styled.span`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 800;
  letter-spacing: 0.01em;
  color: #75c154;
  text-decoration: none;
  cursor: pointer;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Quantity = styled.p`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 600;
  letter-spacing: 0.01em;
`;
