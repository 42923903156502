import { graphql } from "gatsby";
import get from "lodash/get";
import * as React from "react";
import { useCallback } from "react";
import Page from "../components/Page";
import SEO from "../components/SEO";
import {
  CookAtHome,
  CookAtHomeProps,
} from "../components/ShopDetail/CootAtHome";
import { DetailSection } from "../components/ShopDetail/DetailInfo";
import ShopDetailMain, {
  ShopDetailMainProps,
  ShopDetailVariant,
} from "../components/ShopDetail/ShopDetailMain";
import WantMoreBeyond, {
  WantMoreBeyondProps,
} from "../components/ShopDetail/WantMoreBeyond";
import QuestionsAndAnswers, {
  QuestionsAndAnswersProps,
} from "../components/QuestionsAndAnswers";
import { getAssetURL } from "../helpers/assets";
import { cleanHTMLString } from "../helpers/html";
import { parseSEO } from "../helpers/seo";
import { getTranslations } from "../helpers/translations";
import { useIntl } from "../hooks/useIntl";
import { isPublished } from "../helpers/directus";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { ROUTES } from "../helpers/routes";

const Account = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.pack_page_by_id.translations",
      intl.locale
    );
  }, [data]);

  const getProductList = useCallback(
    (variant): DetailSection[] => {
      const { product_list } = variant;

      return product_list
        ?.map(({ quantity_product_id }: { quantity_product_id: any }) => {
          const { product, quantity, status, name } =
            getTranslations(quantity_product_id, "translations", intl.locale) ??
            {};

          const translatedProduct =
            getTranslations(product, "translations", intl.locale) ?? {};

          const detail =
            getTranslations(
              translatedProduct,
              "detail.translations",
              intl.locale
            ) ?? {};

          return {
            status,
            quantity: quantity,
            name,
            smallImage: {
              src: getAssetURL(translatedProduct.small_image),
              alt: translatedProduct.small_image_alt,
            },
            benefitImages: (detail?.badges || []).map(
              ({ badges_id }: { badges_id: any }) => {
                const translatedBadge =
                  getTranslations(badges_id, "translations", intl.locale) ?? {};

                return {
                  src: getAssetURL(translatedBadge?.image),
                  alt: translatedBadge?.image_alt,
                };
              }
            ),
            ingredientsTitle: labels?.ingredientsTitle,
            ingredientsDetail: cleanHTMLString(detail?.ingredients_detail),
            factsTitle: labels?.nutritionsFactsTitle,
            facts: detail?.nutritions_facts_details,
            factsTable: {
              header: [
                get(detail, "nutritions_facts_data[0].title"),
                get(detail, "nutritions_facts_data[0].amount"),
                get(detail, "nutritions_facts_data[0].daily_value"),
              ],
              body: (detail?.nutritions_facts_data || [])
                .filter((e: any, i: number) => i > 0)
                .reduce((results: any, fact: any) => {
                  if (fact.title) {
                    results.push({
                      label: fact?.title,
                      amount: fact?.amount,
                      percentage: fact?.daily_value,
                    });
                  }
                  return results;
                }, []),
            },
            caption: detail?.nutritions_facts_note,
            instructionsTitle: labels?.cookingInstructionsTitle,
            instructions: detail?.methods
              ?.map(({ cooking_methods_id }: { cooking_methods_id: any }) => {
                const translatedMethod =
                  getTranslations(
                    cooking_methods_id,
                    "translations",
                    intl.locale
                  ) ?? {};

                return {
                  status: translatedMethod?.status,
                  title: translatedMethod?.title,
                  description: translatedMethod?.description,
                  logos: translatedMethod?.icons_list
                    ?.map(
                      ({
                        cooking_methods_icons_id,
                      }: {
                        cooking_methods_icons_id: any;
                      }) => {
                        const icon =
                          getTranslations(
                            cooking_methods_icons_id,
                            "translations",
                            intl.locale
                          ) ?? {};

                        return {
                          status: icon?.status,
                          src: getAssetURL(icon?.image),
                          alt: icon?.image_alt,
                        };
                      }
                    )
                    ?.filter(({ status }: { status: string }) =>
                      isPublished(status)
                    ),
                };
              })
              ?.filter(({ status }: { status: string }) => isPublished(status)),
          };
        })
        ?.filter(({ status }: { status: string }) => isPublished(status));
    },
    [data, intl]
  );

  const getShopDetailMainData = (): ShopDetailMainProps => {
    const translatedData = getRootTranslatedData();

    const { variant_list, status } =
      getTranslations(
        translatedData,
        "pack_content_pack.translations",
        intl.locale
      ) ?? {};

    const translatedVariants = isPublished(status)
      ? variant_list
          ?.map(({ pack_variant_id }: { pack_variant_id: any }) => {
            const variant =
              getTranslations(pack_variant_id, "translations", intl.locale) ??
              {};

            return { variant_slug: pack_variant_id?.variant_slug, ...variant };
          })
          ?.filter(({ status }: { status: string }) => isPublished(status))
      : [];

    return {
      isPublished: isPublished(translatedData?.pack_content_status),
      quantityLabel: labels?.quantityLabel,
      contentTitle: labels?.contentTitle,
      viewProductLabel: labels?.viewProductLabel,
      collapseProductLabel: labels?.collapseProductLabel,
      flavorsLabel: labels?.flavorsLabel,
      buttonLabel: labels?.addToCart,
      notAvailableLabel: labels?.outOfStock,
      variantList: translatedVariants?.map(
        (variant: any): ShopDetailVariant => {
          const translatedProduct =
            getTranslations(
              variant,
              "shoppable_product.translations",
              intl.locale
            ) ?? {};

          return {
            id: variant?.id,
            slug: variant?.variant_slug,
            title: variant?.title,
            shippingLabel: variant?.shipping_label,
            packImage: {
              src: getAssetURL(translatedProduct.large_image) ?? "",
              alt: translatedProduct.large_image_alt,
            },
            variantTitle: translatedProduct.variant_title,
            productTitle: translatedProduct.product_title,
            productNumberId: translatedProduct.product_number_id,
            sku: translatedProduct.variant_sku,
            largeDescription: translatedProduct.large_description,
            productList: getProductList(variant),
            shippingModalContent: variant?.shipping_modal_content,
          };
        }
      ),
    };
  };

  const getWantMoreData = (): WantMoreBeyondProps => {
    const section = getRootTranslatedData();

    return {
      isPublished: isPublished(section?.want_more_beyond_status),
      title: section?.want_more_beyond_title,
      ctaLabel: labels?.go,
      ctaLink: section?.want_more_beyond_button_link,
      productTitle: section?.want_more_beyond_subtitle,
      productSubtitle: section?.want_more_beyond_description,
      image: {
        src: getAssetURL(section?.want_more_beyond_image) ?? "",
        alt: section?.want_more_beyond_image_alt,
      },
    };
  };

  const getCookAtHomeData = (): CookAtHomeProps => {
    const section = getRootTranslatedData();

    const { recipe } =
      getTranslations(
        section,
        "cook_it_at_home_link.translations",
        intl.locale
      ) ?? {};

    const translatedRecipe =
      getTranslations(recipe, "translations", intl.locale) ?? {};

    const { product } =
      getTranslations(recipe, "product_page.translations", intl.locale) ?? {};

    const { product_name } =
      getTranslations(product, "translations", intl.locale) ?? {};

    return {
      isPublished: isPublished(section?.cook_it_at_home_status),
      ctaLabel: labels?.moreRecipes,
      ctaLink: ROUTES.RECIPES,
      image: {
        src: getAssetURL(translatedRecipe?.image) ?? "",
        alt: translatedRecipe?.image_alt,
      },
      title: section?.cook_it_at_home_title,
      description: section?.cook_it_at_home_description,
      recipeSubtitle: translatedRecipe?.title,
      recipeTitle: product_name,
      recipeLink: `${ROUTES.RECIPE_DETAIL}/${section?.cook_it_at_home_link?.recipe_page_slug}`,
    };
  };

  const getQuestionsData = useCallback((): QuestionsAndAnswersProps | null => {
    const translatedData = getRootTranslatedData();
    if (!isPublished(translatedData?.faq_status)) return null;

    return {
      title: translatedData?.faq_title,
      subtitle: translatedData?.faq_subtitle,
      button: {
        label: labels?.viewAllFaqs,
        link: ROUTES.FAQS,
      },
      cards: translatedData?.faq_cards?.map(
        (card: { title: string; description: string }) => ({
          title: card.title,
          description: card.description,
        })
      ),
    };
  }, [getRootTranslatedData, intl]);

  return (
    <Page
      headerBackgroundColor={"#012102"}
      helmet={
        <SEO {...parseSEO(data, "pack_detail_page_by_id", intl?.locale)} />
      }
    >
      <ShopDetailMain {...getShopDetailMainData()} />
      <WantMoreBeyond {...getWantMoreData()} />
      <CookAtHome {...getCookAtHomeData()} />
      <QuestionsAndAnswers {...getQuestionsData()} />
    </Page>
  );
};

export default Account;

export const query = graphql`
  query ($id: ID!, $locale: String) {
    directus {
      pack_page_by_id(id: $id) {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          id
          cook_it_at_home_description
          want_more_beyond_button_link
          faq_cards
          languages_code {
            code
            name
          }
          status
          pack_content_status
          want_more_beyond_status
          cook_it_at_home_status
          faq_status
          want_more_beyond_title
          want_more_beyond_subtitle
          want_more_beyond_description
          want_more_beyond_image_alt
          cook_it_at_home_title
          faq_title
          faq_subtitle
          pack_content_pack {
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                code
                name
              }
              status
              variant_list {
                pack_variant_id {
                  variant_slug
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    id
                    status
                    title
                    shipping_label
                    shipping_modal_content
                    shoppable_product {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        product_number_id
                        variant_sku
                        product_title
                        variant_title
                        small_description
                        large_description
                        small_image_alt
                        small_image {
                          id
                          filename_disk
                        }
                        large_image_alt
                        large_image {
                          id
                          filename_disk
                        }
                      }
                    }
                    product_list {
                      quantity_product_id {
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          status
                          quantity
                          name
                          product {
                            variant_slug
                            translations(
                              filter: {
                                languages_code: { code: { _eq: $locale } }
                              }
                            ) {
                              languages_code {
                                code
                                name
                              }
                              status
                              title
                              subtitle
                              description
                              small_image_alt
                              small_image {
                                id
                                filename_disk
                              }
                              large_image_alt
                              large_image {
                                id
                                filename_disk
                              }
                              options {
                                available_quantity_id {
                                  translations(
                                    filter: {
                                      languages_code: { code: { _eq: $locale } }
                                    }
                                  ) {
                                    languages_code {
                                      code
                                      name
                                    }
                                    status
                                    quantity
                                    image_alt
                                    image {
                                      id
                                      filename_disk
                                    }
                                  }
                                }
                              }
                              detail {
                                translations(
                                  filter: {
                                    languages_code: { code: { _eq: $locale } }
                                  }
                                ) {
                                  languages_code {
                                    code
                                    name
                                  }
                                  status
                                  badges {
                                    badges_id {
                                      translations(
                                        filter: {
                                          languages_code: {
                                            code: { _eq: $locale }
                                          }
                                        }
                                      ) {
                                        languages_code {
                                          code
                                          name
                                        }
                                        status
                                        image_alt
                                        image {
                                          id
                                          filename_disk
                                        }
                                      }
                                    }
                                  }
                                  ingredients_detail
                                  nutritions_facts_details
                                  nutritions_facts_note
                                  nutritions_facts_data
                                  methods {
                                    cooking_methods_id {
                                      translations(
                                        filter: {
                                          languages_code: {
                                            code: { _eq: $locale }
                                          }
                                        }
                                      ) {
                                        languages_code {
                                          code
                                          name
                                        }
                                        status
                                        title
                                        description
                                        icons_list {
                                          cooking_methods_icons_id {
                                            translations(
                                              filter: {
                                                languages_code: {
                                                  code: { _eq: $locale }
                                                }
                                              }
                                            ) {
                                              languages_code {
                                                code
                                                name
                                              }
                                              status
                                              image_alt
                                              image {
                                                id
                                                filename_disk
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          want_more_beyond_image {
            filename_disk
            id
          }
          cook_it_at_home_link {
            recipe_page_slug
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                code
                name
              }
              recipe {
                product_page {
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    product {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        product_name
                      }
                    }
                  }
                }
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  title
                  image_alt
                  image {
                    id
                    filename_disk
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
