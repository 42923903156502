import React from "react";
import styled from "styled-components";
import {
  rem,
  toREM,
  toVWDesktop,
  toVWMobile,
  vwDesktop,
  vwMobile,
} from "../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../helpers/theme";
import { Image } from "../../types";

export type DetailInfoProps = {
  product: DetailSection;
};

export type DetailSection = {
  name: string;
  smallImage: Image;
  main_product: boolean;
  image_1: string;
  image_1_alt: string;
  title_1?: string;
  title_2?: string;
  subtitle?: string;
  benefitImages?: Image[];
  ingredientsTitle?: string;
  ingredientsDetail?: string;
  description?: string;
  factsTitle?: string;
  facts?: string;
  factsTable?: FactsTable;
  caption?: string;
  instructionsTitle?: string;
  instructions?: Instruction[];
};

type FactsTable = {
  header: string[];
  body: SimpleFact[];
};

type SimpleFact = {
  label?: string;
  amount?: string;
  percentage?: string;
};

type Instruction = {
  logos?: Image[];
  title?: string;
  description?: string;
};

const DetailInfo = ({ product }: DetailInfoProps): JSX.Element => {
  return (
    <InfoContainer>
      <BadgeContainer>
        {product?.benefitImages?.map((benefit, index) => (
          <Badge key={index} src={benefit?.src} alt={benefit?.alt} />
        ))}
      </BadgeContainer>
      <SectionTitle>{product?.ingredientsTitle}</SectionTitle>
      <Description
        dangerouslySetInnerHTML={{
          __html: product?.ingredientsDetail,
        }}
      />
      <SectionTitle>{product?.factsTitle}</SectionTitle>
      <FactWrapper
        dangerouslySetInnerHTML={{
          __html: product?.facts,
        }}
      />
      <Table>
        <TableRow>
          {product?.factsTable?.header?.map((header, index) => {
            if (index === 0) {
              return <TableData key={index} />;
            }
            return (
              <TableHeader aria-label={header} key={index}>
                {header}
              </TableHeader>
            );
          })}
        </TableRow>
        {product?.factsTable?.body?.map((row, index) => (
          <TableRow key={index}>
            <TableDataLabel>{row?.label}</TableDataLabel>
            <TableData>{row?.amount}</TableData>
            <TableData>{row?.percentage}</TableData>
          </TableRow>
        ))}
      </Table>
      <Description className="nutrition">{product?.caption}</Description>
      <SectionTitle>{product?.instructionsTitle}</SectionTitle>
      {product?.instructions?.map((instruction, index) => (
        <InstructionCard key={index}>
          <InstructionLogoWrapper>
            {instruction?.logos?.map((logo, indexLogo) => (
              <IntructionImage
                key={indexLogo}
                src={logo?.src}
                alt={logo?.alt}
              />
            ))}
          </InstructionLogoWrapper>
          <InstructionTitle>{instruction.title}</InstructionTitle>
          <Description
            className="instruction"
            dangerouslySetInnerHTML={{ __html: instruction?.description }}
          />
        </InstructionCard>
      ))}
    </InfoContainer>
  );
};
export default DetailInfo;

const InfoContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: ${vwMobile(20)};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    justify-content: flex-start;
    gap: ${vwDesktop(20)};
  }
`;

const Badge = styled.img`
  width: auto;
  height: ${toVWMobile(118)}vw;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: auto;
    height: ${toVWDesktop(110)}vw;
  }
`;

const SectionTitle = styled.h3`
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: ${toREM(52)}rem;
  margin: ${toVWMobile(40)}vw 0 ${toVWMobile(28)}vw;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(48)}rem;
    line-height: ${toREM(60)}vw;
    margin: ${toVWDesktop(57)}vw 0 ${toVWDesktop(44)}vw;
  }
`;

const Description = styled.p`
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  line-height: ${rem(28)};
  margin: 0 0 ${toVWMobile(60)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: 0 0 ${toVWDesktop(60)}vw;
  }

  & ul {
    padding: 0 ${toVWMobile(20)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding: 0 ${toVWDesktop(25)}vw;
    }
  }

  &.nutrition {
    margin: ${toVWMobile(25)}vw 0 ${toVWMobile(30)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin: ${toVWDesktop(25)}vw 0 ${toVWDesktop(30)}vw;
    }
  }

  &.instruction {
    margin-bottom: ${toVWMobile(42)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(42)}vw;
    }
  }
`;

const FactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(28)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(28)}vw;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  width: 100%;
`;

const TableRow = styled.tr`
  color: #ffffff;
  border-bottom: ${toVWMobile(1)}vw solid #3a6249;
`;

const TableHeader = styled.th`
  font-size: ${toREM(14)}rem;
  font-weight: 600;
  line-height: ${toREM(18)}rem;
  text-align: right;
  padding: ${toVWMobile(15)}vw 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(15)}vw 0;
  }
`;

const TableData = styled.td`
  text-align: right;
  font-size: ${toREM(18)}rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.01em;
  padding: ${toVWMobile(15)}vw 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(15)}vw 0;
  }
`;

const TableDataLabel = styled(TableData)`
  font-weight: 800;
  text-align: left;
  min-width: ${toVWMobile(120)}vw;
  max-width: ${toVWMobile(120)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    min-width: ${toVWDesktop(120)}vw;
    max-width: ${toVWDesktop(120)}vw;
  }
`;

const InstructionCard = styled.div``;

const InstructionLogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${vwMobile(20)};
  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(20)};
  }
`;

const IntructionImage = styled.img`
  height: ${toVWMobile(72)}vw;
  width: ${toVWMobile(72)}vw;
  margin-bottom: ${toVWMobile(26)}vw;
  margin-right: ${toVWMobile(24)};
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(72)}vw;
    width: ${toVWDesktop(72)}vw;
    margin-bottom: ${toVWDesktop(26)}vw;
    margin-right: ${toVWDesktop(24)};
  }
`;

const InstructionTitle = styled.h4`
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: ${toREM(40)}rem;
  margin-bottom: ${toVWMobile(12)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(36)}rem;
    line-height: ${toREM(30)}rem;
    margin-bottom: ${toVWDesktop(10)}vw;
  }
`;
