import React, { FC } from "react";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { Image } from "../../types";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";

export type WantMoreBeyondProps = {
  isPublished?: boolean;
  title: string;
  productTitle: string;
  productSubtitle: string;
  ctaLabel: string;
  ctaLink: string;
  image: Image;
};

export const WantMoreBeyond: FC<WantMoreBeyondProps> = (
  props: WantMoreBeyondProps
) => {
  if (
    (!props?.title &&
      !props?.productTitle &&
      !props?.productSubtitle &&
      !props?.image) ||
    !props?.isPublished
  ) {
    return null;
  }

  return (
    <Container>
      {props?.title && <Title>{props?.title}</Title>}
      <TryProductContainer>
        <TryProductText>
          {props?.productTitle && (
            <TryProductTitle>{props?.productTitle}</TryProductTitle>
          )}
          {props?.productSubtitle && (
            <TryProductSubtitle>{props?.productSubtitle}</TryProductSubtitle>
          )}
          <ButtonLink
            type={ButtonType.PRIMARY_LIGHT}
            className="cta"
            blank={false}
            link={props?.ctaLink}
            label={props?.ctaLabel}
          />
        </TryProductText>
        <TryProductImage src={props?.image?.src} alt={props?.image?.alt} />
      </TryProductContainer>
    </Container>
  );
};
export default WantMoreBeyond;

const Container = styled.section`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  background: #7a3818;
  padding-block: ${vwMobile(40)};
  padding-inline: ${vwMobile(24)};
  ${MEDIA_DESKTOP} {
    padding-inline: ${vwDesktop(207)} ${vwDesktop(203)};
    padding-block: ${vwDesktop(80)} ${vwDesktop(151)};
  }
`;

const Title = styled.h2`
  font-size: ${rem(36)};
  line-height: ${rem(40)};
  font-weight: 900;
  letter-spacing: 0.01em;
  color: #dd9271;
  margin-bottom: ${vwMobile(150)};

  ${MEDIA_DESKTOP} {
    font-size: ${rem(66)};
    line-height: ${rem(60)};
    font-weight: 900;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin-bottom: ${vwDesktop(91)};
    text-align: center;
  }
`;

const TryProductContainer = styled.div`
  background: #4d2715;
  position: relative;

  display: flex;
  flex-direction: column-reverse;

  ${MEDIA_DESKTOP} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: ${vwDesktop(1030)};
    min-height: ${vwDesktop(426)};
  }
`;

const TryProductText = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: ${vwMobile(0)} ${vwMobile(24)};
  padding-inline: ${vwMobile(24)} ${vwMobile(28)};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(423)};
    padding-block: ${vwDesktop(40)} 0;
    padding-inline: ${vwDesktop(50)} 0;
    .cta {
      width: ${vwDesktop(180)};
    }
  }

  .cta {
    align-self: start;
    > span {
      background: none !important;
    }
    > div {
      background: #dd9271 !important;
      transition: background-color 0.3s;
    }
    &:hover {
      > div {
        background: #f7c1a9 !important;
      }
    }
  }
`;

const TryProductTitle = styled.h3`
  font-weight: 900;
  font-size: ${rem(36)};
  line-height: ${rem(40)};
  letter-spacing: 0.03em;
  margin-bottom: ${vwDesktop(11)};
  color: #dd9271;

  ${MEDIA_DESKTOP} {
    font-size: ${rem(48)};
    line-height: ${rem(56)};
    margin-bottom: ${vwDesktop(15)};
  }
`;

const TryProductSubtitle = styled.h4`
  font-size: ${rem(24)};
  line-height: ${rem(37)};
  font-weight: 900;
  letter-spacing: 0.03em;
  color: #fff;
  margin-bottom: ${vwDesktop(25)};

  ${MEDIA_DESKTOP} {
    font-size: ${rem(36)};
    line-height: ${rem(40)};
    margin-bottom: ${vwDesktop(39)};
  }
`;

const TryProductImage = styled.img`
  margin-top: ${vwMobile(-110)};
  width: ${vwMobile(331)};
  height: ${vwMobile(245)};
  margin-bottom: ${vwMobile(11)};

  ${MEDIA_DESKTOP} {
    margin-top: ${vwDesktop(-48.5)};
    margin-right: ${vwDesktop(-47)};
    margin-bottom: ${vwMobile(0)};

    width: ${vwDesktop(533)};
    height: ${vwDesktop(400)};
  }
`;
