import Loadable from "@loadable/component";
import { useMediaQuery } from "@react-hook/media-query";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getQueryParams } from "../../helpers/location";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA, MEDIA_DESKTOP } from "../../helpers/theme";
import { Image } from "../../types";
import Accordion from "./Accordion";
import { DetailSection } from "./DetailInfo";

const LoadableShopDetailCarousel = Loadable(
  () => import("./ShopDetailCarousel")
);

export type ShopDetailVariant =
  | {
      id: string;
      slug: string;
      title: string;
      packImage: Image;
      variantTitle: string;
      productTitle: string;
      largeDescription: string;
      productList: DetailSection[];
      productNumberId: string;
      sku: string;
      shippingModalContent: string;
      shippingLabel: string;
    }
  | undefined;

export type ShopDetailMainProps = {
  isPublished?: boolean;
  eyebrowLabel?: string;
  contentTitle: string;
  viewProductLabel: string;
  collapseProductLabel: string;
  flavorsLabel: string;
  monthlyFeesLabel?: string;
  quantityLabel: string;
  variantList: ShopDetailVariant[];
  buttonLabel: string;
  notAvailableLabel: string;
};

const ShopDetailMain: React.FC<ShopDetailMainProps> = (
  props: ShopDetailMainProps
) => {
  if (!props?.isPublished) return null;

  const containerRef = useRef<HTMLDivElement>(null);
  if (!props?.variantList?.length) return null;

  const [selectedVariant, setSelectedVariant] = useState<ShopDetailVariant>(
    props?.variantList?.[0]
  );

  const params = getQueryParams();

  const [productOpen, setProductOpen] = useState<number>();

  const isDesktop = useMediaQuery(MEDIA.DESKTOP);

  useEffect(() => {
    setProductOpen(undefined);
  }, [selectedVariant]);

  useEffect(() => {
    if (params?.variant)
      setSelectedVariant(
        props?.variantList?.find((v) => v?.slug === params.variant)
      );
  }, [params?.variant, props?.variantList]);

  const textComponent = (
    <>
      <Title dangerouslySetInnerHTML={{ __html: selectedVariant?.title }} />
      <Description>{selectedVariant?.largeDescription}</Description>
    </>
  );

  return (
    <Container ref={containerRef}>
      <LeftSide>
        {isDesktop && textComponent}
        <PackageIncludes>{props?.contentTitle}</PackageIncludes>
        <Accordion
          viewProductLabel={props.viewProductLabel}
          collapseProductLabel={props.collapseProductLabel}
          quantityLabel={props?.quantityLabel}
          items={selectedVariant?.productList}
          onChange={setProductOpen}
          open={productOpen}
        />
      </LeftSide>
      <LoadableShopDetailCarousel
        containerRef={containerRef}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        flavorsLabel={props?.flavorsLabel}
        monthlyFeesLabels={props?.monthlyFeesLabel}
        shippingLabel={selectedVariant?.shippingLabel}
        shippingModalContent={selectedVariant?.shippingModalContent}
        variantList={props?.variantList}
        textComponent={textComponent}
        buttonLabel={props?.buttonLabel}
        notAvailableLabel={props?.notAvailableLabel}
      />
    </Container>
  );
};
export default ShopDetailMain;

const Container = styled.div`
  background: #033305;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  ${MEDIA_DESKTOP} {
    display: flex;
    flex-direction: row;
    padding-left: ${vwDesktop(205)};
    padding-bottom: ${vwDesktop(80)};
  }
`;

const LeftSide = styled.div`
  padding-inline: ${vwMobile(24)};
  padding-block: ${vwMobile(51)} ${vwMobile(85)};
  width: 100%;

  ${MEDIA_DESKTOP} {
    padding-inline: 0;
    padding-block: 0;
    padding-top: ${vwDesktop(40)};
    width: ${vwDesktop(457)};
  }
`;

const Title = styled.div`
  > * {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: ${vwMobile(15)};
    font-size: ${rem(48)};
    line-height: ${rem(52)};
    letter-spacing: 0.03em;
    font-weight: 900;
    ${MEDIA_DESKTOP} {
      font-size: ${rem(80)};
      line-height: ${rem(80)};
      margin-bottom: ${vwDesktop(15)};
    }
    b,
    strong {
      color: #75c154;
      font-weight: inherit;
    }
  }
`;

const Description = styled.p`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 700;
  color: #fff;
  margin-bottom: ${vwMobile(39)};

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(41)};
    width: ${vwDesktop(400)};
  }
`;

const PackageIncludes = styled.p`
  text-transform: uppercase;
  font-size: ${rem(24)};
  line-height: ${rem(37)};
  font-weight: 900;
  letter-spacing: 0.06em;
  color: #fff;

  margin-bottom: ${vwMobile(25)};

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(17)};
  }
`;
